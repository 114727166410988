@charset "utf-8";

$base-font-family: 'Titillium Web', sans-serif;
$base-font-weight: 300;
$medium-font-weight: 400;
$heavy-font-weight: 600;
$font-size-base: 20px;
$font-size-tiny: 0.5rem;
$font-size-small: 0.8rem;
$font-size-medium: 2rem;
$font-size-large: 4rem;
$font-size-xlarge: 8rem;
$line-height-base: 1.6rem;
$line-height-tiny: 0.5rem;
$line-height-small: 0.8rem;
$line-height-medium-nospace: 2rem;
$line-height-medium: 3rem;
$line-height-large: 3rem;
$line-height-xlarge: 6rem;


// Colour Settings
$true-black-color: #151515;
$black-color: #1E1E1E;
$grey-color: #8d8d8d;
$white-color: #fcfcfc;
$accent-color: #00656E;

// Extreme sizes
$content-max-width: 1390px;
$content-min-width: 375px;
$max-article-width: 800px;


// Content Image Thumbnail Widths
$thumbnail-large-width: 500px;
$thumbnail-small-width: 300px;
$thumbnail-xsmall-width: 150px;


// Layout Settings
$spacing-unit-blockout: 2px;
$spacing-unit-tiny: 5px;
$spacing-unit-small: 15px;
$spacing-unit-medium: 25px;
$spacing-unit-large: 50px;
$spacing-unit-xlarge: 80px;

$caption-width: 100px;
$gallery-padding: 10px;
$gallery-padding-negative: -10px;

$image-border-width: 2px;
$overline-width: 1px;


// Import partials from `sass_dir` (defaults to `_sass`)
@import
  "base"
;
