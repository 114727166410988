/**
 * Reset basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure,
img {
  margin: 0;
  padding: 0;
  text-align: left;
}

html,
body,
div {
  box-sizing: border-box; //consistent box sizing
}

img {
  display: block; //Prevents extra space below img
}

img.square {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

img.thumbnail {
  width: 100%;
  max-width: $thumbnail-small-width;
}

img.full-width {
  width: 100%;
  max-width: 100%;
}


/**
 * Basic styling
 */
body {
  font-size: $font-size-base;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  line-height: $line-height-base;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;

  border: 0;

  color: $black-color;
  background-color: $white-color;
}

h1,
h2,
h3 {
  font-weight: $base-font-weight;
}

h1 {
  font-size: $font-size-xlarge;
  line-height: $line-height-xlarge;
}

h2 {
  font-size: $font-size-medium;
  line-height: $line-height-medium;
  padding-top: 1rem;
}

h3 {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

a {
  text-decoration: none;
  color: $white-color;
  background-color: $black-color;
  padding-left: $spacing-unit-blockout;
  padding-right: $spacing-unit-blockout;
  border-radius: $spacing-unit-blockout;
}

p {
  padding-bottom: 1rem;
}


/**
* Reusable Classes 
*/
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: $spacing-unit-small;
}

.grid-item:nth-child(odd) {
  grid-column: 1 / span 6;
}

.grid-item:nth-child(even) {
  grid-column: 7 / span 6;
}

.image-frame img {
  width: 100%;
}

.background-black {
  background-color: $black-color;
  color: $white-color;
}

.background-white {
  background-color: $black-color;
  color: $white-color;
}

.padding-large {
  padding: $spacing-unit-large;
}

.small-font {
  font-size: $font-size-small;
  line-height: 1rem;
}

.hidden {
  display: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/**
 * Header and Navigation bar
 */
.header {
  width: 100%;
  height: $spacing-unit-xlarge;
  background-color: $white-color;
  color: $black-color;
}

.header .content {
  max-width: $content-max-width;
  padding-left: $spacing-unit-small;
  padding-right: $spacing-unit-small;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.header .content .title {
  font-size: $font-size-medium;
  line-height: $spacing-unit-xlarge;
}

.header .content .title a {
  background: none;
  color: $black-color;
}

.header .content nav {
  flex-grow: 1;
  text-align: right;
}

.header .content nav a {
  font-size: $font-size-small;
  line-height: $line-height-medium-nospace;
  text-decoration: underline;
  background: none;
  color: $black-color;
}

.header .content nav a.nodecoration {
  text-decoration: none;
}

.header.black {
  background-color: $black-color;
  color: $white-color;
  height: $line-height-medium-nospace;
}

.header.black .content .title a {
  color: $white-color;
}

.header.black .content nav a {
  color: $white-color;
}

.header.black .content .title {
  font-size: $font-size-small;
  line-height: $line-height-medium-nospace;
}


/**
* About
*/
.about {
  max-width: $max-article-width;
  margin-left: auto;
  margin-right: auto;
  margin-top: $spacing-unit-xlarge;
  padding-left: $spacing-unit-small;
  padding-right: $spacing-unit-small;

  font-size: $font-size-small;
  line-height: 1rem;
}

.about h2 {
  padding-top: 0;
}

.about a {
  text-decoration: underline;
  color: $black-color;
  background: none;
  padding: 0;
  border: 0;
}

.about .grid .logo {
  grid-column: 3 / span 8;
  margin-bottom: $spacing-unit-small;
}


.about .grid-item {
  grid-column: 3 / span 4;
}

.about .grid-item.right {
  grid-column: 7 / span 4;
}


/**
* Archive
*/
.archive {
  max-width: $max-article-width;
  margin-left: auto;
  margin-right: auto;
  margin-top: $spacing-unit-xlarge;
  padding-left: $spacing-unit-small;
  padding-right: $spacing-unit-small;

  font-size: $font-size-small;
  line-height: 1rem;
}

.archive .menu {
  text-align: center;

  padding-top: $spacing-unit-small;
  padding-bottom: $spacing-unit-small;
}

.archive .menu a {
  background-color: $white-color;
  color: $black-color;
  text-decoration: overline;
}

.archive .menu a:hover {
  cursor: pointer;
  text-decoration: overline;
}

.archive h3 {
  font-size: $font-size-small;
  font-weight: $heavy-font-weight;
  line-height: 1rem;
}

.archive a {
  text-decoration: none;
  color: $black-color;
  padding: 0;
  border: 0;
}

.archive a:hover {
  text-decoration: underline;
}

.archive .grid .logo {
  grid-column: 3 / span 8;
  margin-bottom: $spacing-unit-small;
}

.archive .grid-item:nth-child(odd) {
  grid-column: 3 / span 4;
}

.archive .grid-item:nth-child(even) {
  grid-column: 7 / span 4;
}

.archive .grid-item:nth-child(odd) h3,
.archive .grid-item:nth-child(odd) p {
  text-align: right;
}

.archive .grid-item:nth-child(even) h3,
.archive .grid-item:nth-child(even) p {
  text-align: left;
}


/**
* Footer
*/
section.footer {
  margin-top: $spacing-unit-xlarge;
  margin-bottom: $spacing-unit-small;
}

section.footer p {
  font-size: $font-size-small;
  text-align: center;
}

section.footer a {
  background-color: $white-color;
  color: $black-color;
  border: 0;
}


/**
 * Default (blog-post) article
 */
article {
  max-width: $max-article-width;
  margin-left: auto;
  margin-right: auto;
}

article p img {
  max-width: 100%;
}

article ol.references {
  font-size: $font-size-small;
}

article p img.full-spaced {
  padding-top: $spacing-unit-tiny;
}

article section.title h2 {
  font-weight: $heavy-font-weight;
}

article section.title h2 span {
  font-weight: $base-font-weight;
}

article.blog section {
  padding-left: $spacing-unit-small;
  padding-right: $spacing-unit-small;
}


/**
 * blog-photo article
 */
article.blog-photo {
  max-width: $content-max-width;
  margin-top: 0;
}

article.blog-photo img {
  display: block;
}

article.blog-photo img.pad {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

article.blog-photo p {
  padding: 0;
}

article.blog-photo p.square-text-above {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

article.blog-photo p.square-text-below {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}


/**
 * photography article
 */
article.photography {
  max-width: 100%;
}

article.photography p {
  padding-bottom: 0;
}

article.photography section.introduction {
  padding-left: $spacing-unit-tiny;
  padding-right: $spacing-unit-tiny;
  font-size: $font-size-small;
  line-height: $line-height-small;
  text-align: right;
}

article.photography section.introduction p {
  text-align: right;
}

article.photography section.gallery-masonry {
  columns: 4 300px;
  column-gap: 0;
  margin: $spacing-unit-tiny;
}

article.photography section.gallery-masonry .image-frame {
  width: 100%;
  display: block;
  padding: 0;
  padding: $spacing-unit-tiny;
}


/**
 * music article
 */
article.music {
  max-width: 100%;
}

article.music p {
  padding-bottom: 0;
}

article.music a {
  color: inherit;
  background-color: inherit;
  padding: 0;
  border-radius: 0;
}

article.music nav a.selected {
  background-color: $black-color;
}

article.music nav a {
  text-decoration: none;
  color: $white-color;
  background-color: $grey-color;
  padding-left: $spacing-unit-tiny;
  padding-right: $spacing-unit-tiny;
  border-radius: $spacing-unit-blockout;
  cursor: pointer;
}

article.music section.title {
  margin-left: $spacing-unit-tiny;
  padding-left: $spacing-unit-tiny;
  margin-right: $spacing-unit-tiny;
  padding-right: $spacing-unit-tiny;
}

article.music section.title p {
  font-size: $font-size-small;
  line-height: $line-height-small;
  text-align: right;
}


article.music section.title.albums-title h2 {
  display: inline;
}

article.music section.title.albums-title div {
  display: inline;
}

article.music section.title.albums-title div a {
  text-decoration: underline;
  padding-left: $spacing-unit-tiny;
  font-size: $font-size-small;
  line-height: $line-height-small;
}

article.music section.music-masonry {
  columns: 8 150px;
  column-gap: 0;
  grid-auto-flow: column;
  margin: $spacing-unit-tiny;
}

article.music section.music-masonry .image-frame {
  width: 100%;
  display: block;
  padding: 0;
  padding: $spacing-unit-tiny;
  position: relative;
}

article.music section.music-masonry .image-frame .description {
  position: absolute;
  bottom: 0;
  left: $spacing-unit-tiny;
  padding-right: $spacing-unit-tiny;
  z-index: 100;
  background-color: $white-color;
}

article.music section.music-alphabetic h2 {
  margin-left: $spacing-unit-tiny;
  padding-left: $spacing-unit-tiny;
  margin-right: $spacing-unit-tiny;
  padding-right: $spacing-unit-tiny;
  padding-top: 0;
}

article.music section.music-alphabetic .album {
  margin-left: $spacing-unit-tiny;
  padding-left: $spacing-unit-tiny;
  margin-right: $spacing-unit-tiny;
  padding-right: $spacing-unit-tiny;
  margin-bottom: $spacing-unit-tiny;
  padding-bottom: $spacing-unit-tiny;
  margin-top: $spacing-unit-tiny;
  padding-top: $spacing-unit-tiny;
  ;
  display: flex;
}

article.music section.music-alphabetic .image-frame {
  width: 150px;
  min-width: 150px;
  margin-right: $spacing-unit-tiny;
}

article.music section.music-alphabetic .description {
  margin-left: $spacing-unit-tiny;
}


/**
 * Gallery
 */
ul.gallery {
  list-style-type: none;
  column-count: 3;
  column-gap: $spacing-unit-tiny;
  padding-top: 0;
  background-color: $black-color;
  padding-left: $spacing-unit-tiny;
  padding-right: $spacing-unit-tiny;
}

ul.gallery li {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 0;
  break-inside: avoid;
  padding-top: $spacing-unit-tiny;
}

ul.gallery li img {
  width: 100%;
}


@media (max-width: 1100px) {}

@media (max-width: 800px) {
  ul.gallery {
    column-count: 1;
    column-gap: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 620px) {
  .header {
    height: $line-height-medium-nospace;
  }

  .header .content .title {
    font-size: $font-size-small;
    line-height: $line-height-medium-nospace;
  }

  .archive .grid {
    column-gap: $spacing-unit-small;
    row-gap: 0;
  }

  .archive .grid-item:nth-child(odd) {
    grid-column: 3 / span 8;
  }

  .archive .grid-item:nth-child(even) {
    grid-column: 3 / span 8;
  }

  .archive .grid-item:nth-child(odd) h3,
  .archive .grid-item:nth-child(odd) p {
    text-align: left;
  }

  .archive .grid-item:nth-child(even) h3,
  .archive .grid-item:nth-child(even) p {
    text-align: left;
  }

  .about .grid-item {
    grid-column: 3 / span 8;
    text-align: left;
  }

  .about .grid-item.right {
    grid-column: 3 / span 8;
  }

  article.photography section.gallery-masonry {
    columns: 1;
    column-gap: 0;
    margin: 0;
  }

  article.photography section.gallery-masonry .image-frame {
    padding: 0;
  }

}